:root {
  --border-radius: 8px;
  --header-background: #efefef;
}

@keyframes toggle-modal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

dialog {
  text-align: center;
  border-radius: var(--border-radius);
  border-width: 0;
  border-color: #fff;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  display: flex;
}

dialog h2, p {
  width: 100%;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

dialog.small {
  min-height: 80px;
  min-width: 170px;
}

dialog.medium {
  min-height: 80px;
  min-width: 300px;
}

dialog.large {
  min-height: 600px;
  min-width: 400px;
}

dialog::backdrop {
  background-color: #0000004d;
}

dialog:not([open]) {
  display: none;
}

dialog[open] {
  animation: .2s ease-in-out toggle-modal;
}

dialog header {
  background-color: var(--header-background);
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

dialog header h1 {
  margin-left: 5px;
}

dialog header .close {
  width: 50px;
  height: 50px;
}

dialog textarea {
  height: 100%;
  flex-grow: 1;
}

.left, .right {
  flex-basis: auto;
}

.input {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.input label {
  display: block;
}

/*# sourceMappingURL=index.2aad9ede.css.map */
